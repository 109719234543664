import { SnakeToCamelCaseObject } from 'packages/core/utilsTypes'
import { CoinType } from '@platform/types/coin'
import { AddressType } from '@platform/components/ProbeSandbox/types/converted/ClusterAddress'
import { Token } from '@platform/apiServices'

export enum SORT_BY_MAP {
  received = 1,
  sent = 2,
  trx_in = 4,
  trx_out = 5,
}

export type RawAddressesData = {
  address: {
    cluster_id: number
    extra: {
      type: AddressType
      segwit: boolean
      rbf: boolean
      multisig: [number, number]
      contract?: boolean
    }
    id: number
    address: string
    score: number
  }
  sent: number
  min_max: [number, number]
  entity: {
    name: string
    category: string
    icon: string | null
  }
  received: number
  trx_in: number
  trx_out: number
}

export type AddressesData = SnakeToCamelCaseObject<RawAddressesData>

export type RawAddressesServerData = {
  data: RawAddressesData[]
  pagination: {
    page_number: number
    page_size: number
    total_entries: number
  }
}

export type AddressesServerData = SnakeToCamelCaseObject<RawAddressesServerData>

export interface IAddressesFilterAPIService {
  getAddresses: (
    payload?: AddressesSearchRequestParams
  ) => Promise<AddressesServerData>
  getTokens: (payload?: AddressTokensSearchRequestParams) => Promise<Token[]>
  joinChannel: () => Promise<void>
}

export interface AddressesSearchRequestParams {
  page: number
  currency: CoinType
  first_seen_timestamp: boolean // filter addresses where first transaction timestamp is greater than this value
  timestamp_from: number | Date //timestamp in seconds
  timestamp_to: number | Date //timestamp in seconds
  sent_from?: number
  sent_to?: number
  received_from?: number
  received_to?: number
  balance_from?: number
  balance_to?: number
  token_id?: number
  sort_by?: number
  sort_order?: 'asc' | 'desc'
}

// params in url and in filters
export type AddressesFiltersParams = Omit<
  AddressesSearchRequestParams,
  'sort_by'
> & {
  sort_by?: keyof typeof SORT_BY_MAP
  is_usd?: boolean
  token_symbol?: string
}

export interface AddressTokensSearchRequestParams {
  currency: CoinType
  phrase?: string
}
