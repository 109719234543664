import { action, makeObservable, observable, computed } from 'mobx'
import { injectable } from 'inversify'
import { NodeAttributes, IconSatellite } from '@clain/graph'
import { roundToPrecision } from '@clain/core/utils/math'
import type { ScoreRounded } from 'packages/core/ui-kit'
import {
  icon,
  iconBlockchain,
} from '@caudena/web-platform/dist/components/Icon/iconFn'

import ProbeNode from './ProbeNode'

import type { Position } from '@clain/graph-entities'
import { NodeSettings, TransactionAddressNodeData } from '../../types'
import type {
  ILayers,
  ILayoutSettingsState,
  IProbeGraph,
  IProbeState,
  ITheme,
} from '../../models'
import getFeatureTagColor from '@clain/core/utils/getFeatureTagColor'

const CORE_RADIUS = 22
const DOT_ORBIT_RADIUS = 5
const DOT_BACKGROUND_COLOR = 'rgba(219, 226, 240, 1)'
const CENTER_ORBIT_RADIUS = 12
const MULTISIG_LABEL_ORBIT_SIZE = 10
const UTXO_ORBIT_SIZE = 42
const LABEL_ORBIT_WIDTH = 42
const LABEL_ORBIT_LOCATION_ANGLE = Math.PI / 2
const LABEL_COLOR = 'rgba(15, 19, 27, 1)'
const LABEL_SIZE = 12
const LABEL_BACKGROUND_COLOR = 'rgba(231, 237, 249, 0.8)'
const LABEL_BORDER_RADIUS = 4
const CLUSTER_LABEL_ORBIT_WIDTH = 58
const CLUSTER_LABEL_ORBIT_LOCATION_ANGLE = Math.PI / 2
const CLUSTER_LABEL_COLOR = 'rgba(99, 123, 171, 1)'
const CLUSTER_LABEL_SIZE = 12
const CLUSTER_LABEL_BACKGROUND_COLOR = 'rgba(231, 237, 249, 0.8)'
const CLUSTER_LABEL_BORDER_RADIUS = 4
const OUTER_BORDER_WIDTH_HIGHLIGHTED = 8
const OUTER_BORDER_COLOR = 'rgba(33, 115, 255, 1)'
const SCORE_LABEL_ORBIT_SIZE = CORE_RADIUS + 2
const GHOSTED_OPACITY = 0.3
const COINBASE_BACKGROUND_COLOR = 'rgba(99, 192, 170, 1)'

@injectable()
export class TransactionAddressNode<
  T extends TransactionAddressNodeData = TransactionAddressNodeData,
> extends ProbeNode<T> {
  @observable public nextUTXOHovered: boolean
  @observable public prevUTXOHovered: boolean
  @observable public nextUTXOLoading: boolean
  @observable public prevUTXOLoading: boolean

  constructor(
    theme: ITheme,
    layers: ILayers,
    layoutSettingsState: ILayoutSettingsState,
    probeState: IProbeState,
    graph: IProbeGraph,
    id: string,
    data: T,
    position: Position,
    settings?: NodeSettings
  ) {
    super(
      theme,
      layers,
      layoutSettingsState,
      probeState,
      graph,
      id,
      data,
      position,
      settings
    )

    makeObservable(this)
  }

  @action
  public setNextUTXOHovered(nextUTXOHovered: boolean) {
    this.nextUTXOHovered = nextUTXOHovered
  }

  @action
  public setPrevUTXOHovered(prevUTXOHovered: boolean) {
    this.prevUTXOHovered = prevUTXOHovered
  }

  @action
  public setNextUTXOLoading(nextUTXOLoading: boolean) {
    this.nextUTXOLoading = nextUTXOLoading
  }

  @action
  public setPrevUTXOLoading(prevUTXOLoading: boolean) {
    this.prevUTXOLoading = prevUTXOLoading
  }

  private generateCoinBaseAttributes(): NodeAttributes<T> {
    const attributes: NodeAttributes<T> = {
      size: CORE_RADIUS,
      fill: COINBASE_BACKGROUND_COLOR,
      shape: 'circle',
      linkType: 'master',
      icon: icon({ variant: 'categoryMining' }),
      iconColor: '#fff',
      iconWidth: 36,
      iconHeight: 36,
      border: {
        color: OUTER_BORDER_COLOR,
        width: 0,
      },
    }

    if (this.highlighted) {
      attributes.border.width = OUTER_BORDER_WIDTH_HIGHLIGHTED
    }

    return attributes
  }

  private generateBaseAttributes(): NodeAttributes<T> {
    const noAddress = this.data.type == 'NS' || this.data.type === 'PKH_MULT'
    const label = noAddress
      ? 'unable to decode'
      : this.data.address?.slice(0, 10)

    const iconSizeNav = parseInt(this.theme.getToken(['icon', 'sm', 'size']))
    const iconSizeUsdt = parseInt(this.theme.getToken(['icon', 'sm', 'size']))
    const iconColor = this.theme.getToken([
      'icon',
      'on',
      'background',
      'variant1',
      'color',
    ])
    const iconColorNav = this.theme.getToken([
      'icon',
      'on',
      'background',
      'variant2',
      'color',
    ])
    const iconColorNavHovered = this.theme.getToken([
      'icon',
      'primary',
      'high',
      'color',
    ])
    const scoreBackgroundColor =
      this.data.score != null
        ? this.theme.getToken([
            'score',
            'tag',
            `score${roundToPrecision(this.data.score) as ScoreRounded}`,
            'background',
            'color',
          ])
        : this.theme.getToken([
            'node',
            'transaction',
            'address',
            'border',
            'color',
          ])
    const scoreTextColor = this.theme.getToken([
      'score',
      'tag',
      `score${roundToPrecision(this.data.score) as ScoreRounded}`,
      'text',
      'color',
    ])

    const backgroundColor = this.theme.getToken([
      'node',
      'transaction',
      'address',
      'background',
      'color',
    ])
    //nodeTransactionAddressBackgroundColor

    const backgroundBorderWidth = this.theme.getToken([
      'node',
      'transaction',
      'address',
      'border',
      'width',
    ])

    const attributes = {
      size: CORE_RADIUS,
      fill: backgroundColor,
      linkType: 'master',
      opacity: this.ghosted ? GHOSTED_OPACITY : undefined,
      shape: 'circle',
      border: {
        color: OUTER_BORDER_COLOR,
        width: 0,
      },
      orbits: [
        {
          size: CORE_RADIUS,
          border: {
            color: scoreBackgroundColor,
            width: backgroundBorderWidth,
            opacity: this.ghosted ? GHOSTED_OPACITY : undefined,
          },
        },
        {
          size: CORE_RADIUS,
        },
        {
          size: CENTER_ORBIT_RADIUS,
        },
        {
          size: MULTISIG_LABEL_ORBIT_SIZE,
          locations: [
            {
              angle: Math.PI * 0.5,
              satellite: {
                type: 'label',
                fontSize: 8,
                color: 'white',
                text: '',
              },
            },
          ],
        },
        {
          size: UTXO_ORBIT_SIZE,
          locations: [
            {
              angle: Math.PI * 2,
              satellite: {
                type: 'icon',
                id: 'nextUTXO',
                color: iconColorNav,
                width: iconSizeNav,
                height: iconSizeNav,
              },
            },
            {
              angle: Math.PI * 1,
              satellite: {
                type: 'icon',
                id: 'prevUTXO',
                color: iconColorNav,
                width: iconSizeNav,
                height: iconSizeNav,
              },
            },
          ],
          virtual: true,
        },
        {
          size: SCORE_LABEL_ORBIT_SIZE,
          locations: [
            {
              angle: Math.PI * 0.5,
              satellite: {
                type: 'label',
              },
            },
            {
              angle: Math.PI * 0.9,
              satellite: {
                type: 'icon',
                width: iconSizeUsdt,
                height: iconSizeUsdt,
                iconColor: iconColor,
                shape: 'circle',
              },
            },
          ],
          virtual: true,
        },
        {
          size: LABEL_ORBIT_WIDTH,
          virtual: true,
          locations: [
            {
              angle: LABEL_ORBIT_LOCATION_ANGLE,
              satellite: {
                type: 'label',
              },
            },
          ],
        },
        {
          size: CLUSTER_LABEL_ORBIT_WIDTH,
          virtual: true,
          locations: [
            {
              angle: CLUSTER_LABEL_ORBIT_LOCATION_ANGLE,
              satellite: {
                type: 'label',
                color: CLUSTER_LABEL_COLOR,
                fontSize: CLUSTER_LABEL_SIZE,
                fill: CLUSTER_LABEL_BACKGROUND_COLOR,
                borderRadius: CLUSTER_LABEL_BORDER_RADIUS,
                padding: [2, 1],
              },
            },
          ],
        },
        {
          size: DOT_ORBIT_RADIUS,
          fill: DOT_BACKGROUND_COLOR,
        },
      ],
    } as NodeAttributes<T>

    if (this.highlighted) {
      attributes.border.width = OUTER_BORDER_WIDTH_HIGHLIGHTED
    }

    if (this.layers.addressFeatures) {
      attributes.icon = undefined

      attributes.orbits[2].fill = backgroundColor

      if (this.data.next?.multisigType) {
        // eslint-disable-next-line no-unsafe-optional-chaining
        const [from, to] = this.data?.next.multisigType

        // @ts-expect-error
        attributes.orbits[3].locations[0].satellite.text = `${from} of ${to}`
      }

      attributes.orbits[1].segments = [
        this.data.type !== 'ND' && {
          angleFrom: Math.PI,
          angleTo: Math.PI * 1.25,
          fill: getFeatureTagColor(this.data.type),
          centerOffset: 2,
        },
        (this.data.rbf || this.data.next?.rbf) && {
          angleFrom: Math.PI * 1.25,
          angleTo: Math.PI * 1.5,
          fill: getFeatureTagColor('RBF'),
          centerOffset: 2,
        },
        (this.data.segwit || this.data.next?.segwit) && {
          angleFrom: Math.PI * 1.5,
          angleTo: Math.PI * 1.75,
          fill: getFeatureTagColor('SEGWIT'),
          centerOffset: 2,
        },
        !this.data.compressed &&
          !this.data.next?.compressed && {
            angleFrom: Math.PI * 1.75,
            angleTo: Math.PI * 2,
            fill: getFeatureTagColor('UCP'),
            centerOffset: 2,
          },
      ].filter(Boolean)
    }

    if (this.layers.score && this.data.score) {
      attributes.orbits[5].locations[0].satellite = {
        type: 'label',
        fontSize: 10,
        color: scoreTextColor,
        text: `${this.data.score.toFixed(2)}`,
        fill: scoreBackgroundColor,
        borderRadius: 4,
        padding: [3, 1],
      }
    }

    if (this.data.token?.symbol === 'USDT') {
      ;(attributes.orbits[5].locations[1].satellite as IconSatellite).icon =
        iconBlockchain({ variant: 'usdt' })
      ;(attributes.orbits[5].locations[1].satellite as IconSatellite).fill =
        this.layoutSettingsState.state.graphBackgroundColor
      ;(attributes.orbits[5].locations[1].satellite as IconSatellite).border = {
        width: 2,
        color: this.layoutSettingsState.state.graphBackgroundColor,
      }
    }

    if (this.layers.trxAddressLabel) {
      attributes.orbits[6].locations[0].satellite = {
        type: 'label',
        text: label,
        color: LABEL_COLOR,
        fontSize: LABEL_SIZE,
        fill: LABEL_BACKGROUND_COLOR,
        borderRadius: LABEL_BORDER_RADIUS,
        padding: [2, 1],
      }
    }

    if (this.layers.addressClusterLabel) {
      attributes.orbits[7].locations[0].satellite = {
        type: 'label',
        text: this.data.name || `${this.data.clusterId}`,
        color: CLUSTER_LABEL_COLOR,
        fontSize: CLUSTER_LABEL_SIZE,
        fill: CLUSTER_LABEL_BACKGROUND_COLOR,
        borderRadius: CLUSTER_LABEL_BORDER_RADIUS,
        padding: [2, 1],
      }
    }

    if (this.hasNextUTXO) {
      if (this.nextUTXOHovered) {
        ;(attributes.orbits[4].locations[0].satellite as IconSatellite).color =
          iconColorNavHovered
      }
      ;(attributes.orbits[4].locations[0].satellite as IconSatellite).icon =
        icon({ variant: 'playerRounded' })
    }

    if (this.hasPrevUTXO) {
      if (this.prevUTXOHovered) {
        ;(attributes.orbits[4].locations[1].satellite as IconSatellite).color =
          iconColorNavHovered
      }
      ;(attributes.orbits[4].locations[1].satellite as IconSatellite).icon =
        icon({ variant: 'playerRoundedLeft' })
    }

    return attributes
  }

  protected generateAttributes() {
    if (this.data.coinbase) {
      return this.generateCoinBaseAttributes()
    }

    return this.generateBaseAttributes()
  }

  @computed
  get hasNextUTXO() {
    if (this.data.token) return false

    const trxHash = this.data.next?.trxHash
    const edges = this.probeState.getEdges

    return Boolean(
      trxHash &&
        !edges.find(
          (edge) => edge.sourceKey === this.key && edge.targetKey === trxHash
        )
    )
  }

  @computed
  get hasPrevUTXO() {
    if (this.data.token) return false

    const trxHash = this.data.previous?.trxHash
    const edges = this.probeState.getEdges

    return Boolean(
      trxHash &&
        !edges.find(
          (edge) => edge.targetKey === this.key && edge.sourceKey === trxHash
        )
    )
  }
}
