import { isEVM, isUnsupportedBlockchain, isUTXO } from '@clain/core/types/coin'
import { StateViewModel } from '@clain/core/utils/mobxUtils'
import { action, computed, makeObservable, observable } from 'mobx'
import { pick } from 'ramda'
import {
  CrossChainSwapFlow,
  CrossChainSwapFlowAddressTypes,
} from '../../../types/converted/CrossChainSwap'
import { TransactionExplorerTrx } from '../../../types/converted/TransactionEvm'
import { TransactionUtxo } from '../../../types/converted/TransactionUtxo'
import { addressKey, transactionKey } from '../../../utils/key'
import { IProbeEvents } from '../../ProbeEvents'
import { IProbeState } from '../../ProbeState'
import { IEntityServices } from '../../services/EntitiesServices/types'
import { DI_PROBE_TYPES } from '@platform/components/ProbeSandbox/di/DITypes'
import { inject, injectable } from 'inversify'

@injectable()
export class ActiveEntityCrossChainSwapFlow {
  private entityState: StateViewModel<CrossChainSwapFlow>
  private entityServices: IEntityServices
  @observable private isLoadingSentTransaction = false
  @observable private isLoadingReceviedTransaction = false

  constructor(
    @inject(DI_PROBE_TYPES.ProbeEvents)
    private probeEvents: IProbeEvents,
    @inject(DI_PROBE_TYPES.ProbeState)
    private probeState: IProbeState
  ) {
    this.entityState = new StateViewModel()
    makeObservable(this)
  }

  public init = (entityServices: IEntityServices) => {
    this.entityServices = entityServices
  }

  private getSwapTransferEvm = (
    address: string,
    transfers: TransactionExplorerTrx['transfers']
  ) => {
    return transfers.find((transfer) => {
      if (
        transfer.sender.address === address ||
        transfer.receiver.address === address
      ) {
        return transfer
      }
    })
  }

  private getSwapTransactionUtxo = (
    address: string,
    transaction: TransactionUtxo
  ): TransactionUtxo => {
    const selectInput = transaction.inputs.find((input) => {
      if (input.address === address) {
        return input
      }
    })

    const selectOutput = transaction.outputs.find((output) => {
      if (output.address === address) {
        return output
      }
    })

    return {
      ...transaction,
      inputs: selectInput ? [selectInput] : transaction.inputs,
      outputs: selectOutput ? [selectOutput] : transaction.outputs,
    }
  }

  private renderTransaction = async (type: 'sent' | 'received') => {
    const selectDirAddress = this.data[type]

    if (isUnsupportedBlockchain(selectDirAddress)) {
      return false
    }

    const { trxHash, currency, address } = selectDirAddress

    const selected =
      type === 'sent'
        ? this.selectedSentTransaction
        : this.selectedReceivedTransaction

    const data = await this.entityServices
      .getServices('explorer', currency)
      .getTransaction(trxHash)

    this.probeEvents.emit(
      !selected
        ? [
            {
              type: 'add_node',
              data: {
                strategy: 'transaction',
                ...(isEVM(data)
                  ? (() => {
                      const swapTransfer = this.getSwapTransferEvm(
                        address,
                        data.transfers
                      )

                      return {
                        type: 'transfer',
                        currency: data.currency,
                        index: 0,
                        from: {
                          hash: swapTransfer.sender.address,
                          id: swapTransfer.sender.addressId,
                          clusterId: swapTransfer.sender.clusterId,
                        },
                        to: {
                          hash: swapTransfer.receiver.address,
                          id: swapTransfer.receiver.addressId,
                          clusterId: swapTransfer.receiver.clusterId,
                        },
                        hash: data.hash,
                        id: data.trxId,
                      }
                    })()
                  : {
                      type: 'input',
                      currency: data.currency,
                      createBy: 'by-trx-id',
                      direction: 'out',
                      id: data.id,
                      hash: data.hash,
                      ...pick(
                        ['inputs', 'outputs'],
                        this.getSwapTransactionUtxo(address, data)
                      ),
                    }),
              },
            },
          ]
        : [{ type: 'delete_node', entity: { key: transactionKey(data) } }],
      {
        animation: true,
        animationType: {
          strategy: 'moveToCentroid',
          scaleStrategy: 'auto',
        },
      }
    )
  }

  @action
  public update(...args: Parameters<typeof this.entityState.initState>) {
    this.entityState.initState(...args)
  }

  @computed
  public get data() {
    return this.entityState.state
  }

  private isSelectedTransaction(data: CrossChainSwapFlowAddressTypes) {
    if (isUnsupportedBlockchain(data) || !data) {
      return false
    }

    const hasTransaction = this.probeState.nodes.has(
      transactionKey({ hash: data.trxHash }, data?.currency)
    )

    let hasAddress = false
    if (isUTXO(data.currency)) {
      const transactionAddresses = this.probeState.getNodesDataByType(
        'data.nodeType',
        'utxo_transaction_address'
      )

      const hasTransactionAddress = transactionAddresses?.find(
        (transactionAddress) => transactionAddress.address === data?.address
      )

      hasAddress = !!hasTransactionAddress
    } else {
      hasAddress = this.probeState.nodes.has(addressKey(data))
    }

    return hasTransaction && hasAddress
  }

  @computed
  public get selectedSentTransaction() {
    return this.isSelectedTransaction(this.data?.sent)
  }

  @computed
  public get selectedReceivedTransaction() {
    return this.isSelectedTransaction(this.data?.received)
  }

  @computed
  public get isRenderSentTransaction() {
    if (isUnsupportedBlockchain(this.data?.sent)) {
      return false
    }

    const inProcessing =
      this.probeEvents.meta.nodesInProcessing[
        transactionKey(
          { hash: this.data?.sent?.trxHash },
          this.data?.sent?.currency
        )
      ]

    return inProcessing || this.isLoadingSentTransaction
  }

  @computed
  public get isRenderReceivedTransaction() {
    if (isUnsupportedBlockchain(this.data?.received)) {
      return false
    }

    const inProcessing =
      this.probeEvents.meta.nodesInProcessing[
        transactionKey(
          { hash: this.data?.received?.trxHash },
          this.data?.received?.currency
        )
      ]

    return inProcessing || this.isLoadingReceviedTransaction
  }

  public renderSentTransaction = async () => {
    try {
      this.isLoadingSentTransaction = true
      await this.renderTransaction('sent')
    } finally {
      this.isLoadingSentTransaction = false
    }
  }

  public renderReceivedTransaction = async () => {
    try {
      this.isLoadingReceviedTransaction = true
      await this.renderTransaction('received')
    } finally {
      this.isLoadingReceviedTransaction = false
    }
  }

  @action
  public clear() {
    this.entityState.clearState()
  }
}
