import { injectable } from 'inversify'
import { NodeAttributes } from '@clain/graph'
import { icon } from '@caudena/web-platform/dist/components/Icon/iconFn'

import ProbeNode from './ProbeNode'
import { formatMoneyByType } from '@clain/core/utils/format'
import { DemixNodeData } from '../../types'
import { convertDurationToHoursAndMinutes } from '../../utils'
import { MIXER_ICON } from '../../constants'
import { MetaInfoSatellite } from 'packages/graph/src/types'

const SHAPE = 'square'
const CORE_RADIUS = 48
const CORE_BACKGROUND_COLOR = '#f4cace'
const CORE_BACKGROUND_COLOR_HOVERED = '#f0b8be'
const OUTER_BORDER_WIDTH_HIGHLIGHTED = 8
const OUTER_BORDER_COLOR = 'rgba(33, 115, 255, 1)'

const metaInfoItemConfig = {
  color: 'rgba(15, 19, 27, 1)',
  fontSize: 12,
  lineHeight: 20,
  gap: 8,
  iconHeight: 14,
  iconWidth: 14,
  padding: [6, 4],
}

@injectable()
export class DemixNode<
  T extends DemixNodeData = DemixNodeData,
> extends ProbeNode<T> {
  protected generateAttributes() {
    const formatMoney = formatMoneyByType({
      isShort: this.letterNotation,
    })

    const formatedDeposit = formatMoney({
      value: this.layoutSettingsState.state.isUsdCurrency
        ? this.data.depositAmountUsd
        : this.data.depositAmount,
      currency: this.layoutSettingsState.state.isUsdCurrency
        ? 'usd'
        : this.data.currency,
    })

    const formatedwithdrawalAmount = formatMoney({
      value: this.layoutSettingsState.state.isUsdCurrency
        ? this.data.withdrawalAmountUsd
        : this.data.withdrawalAmount,
      currency: this.layoutSettingsState.state.isUsdCurrency
        ? 'usd'
        : this.data.currency,
    })

    const iconSize = parseInt(this.theme.getToken(['icon', 'xxs', 'size']))
    const iconColor = this.theme.getToken([
      'icon',
      'on',
      'background',
      'variant1',
      'color',
    ])

    const expandedItems = [
      {
        ...metaInfoItemConfig,
        text: this.data.id,
        icon: MIXER_ICON[this.data.entityId],
      },
      {
        ...metaInfoItemConfig,
        text: `${(this.data.confidenceLevel * 100).toFixed(2)}%`,
        icon: icon({ variant: 'precision' }),
        iconColor,
        iconWidth: iconSize,
        iconHeight: iconSize,
      },
      {
        ...metaInfoItemConfig,
        text: convertDurationToHoursAndMinutes(this.data.duration),
        icon: icon({ variant: 'clock' }),
        iconColor,
        iconWidth: iconSize,
        iconHeight: iconSize,
      },
      {
        ...metaInfoItemConfig,
        text: `${formatedDeposit}`,
        icon: icon({ variant: 'flowIn' }),
        iconColor,
        iconWidth: iconSize,
        iconHeight: iconSize,
      },
      {
        ...metaInfoItemConfig,
        text: `${formatedwithdrawalAmount}`,
        icon: icon({ variant: 'flowOut' }),
        iconColor,
        iconWidth: iconSize,
        iconHeight: iconSize,
      },
    ]
    const defaultItems = [
      {
        ...metaInfoItemConfig,
        text: this.data.id,
        icon: MIXER_ICON[this.data.entityId],
      },
    ]

    const attributes = {
      size: CORE_RADIUS,
      fill: CORE_BACKGROUND_COLOR,
      shape: SHAPE,
      icon: icon({ variant: 'demix' }),
      iconWidth: 24,
      iconHeight: 24,
      linkType: 'master',
      orbits: [
        {
          size: CORE_RADIUS + 2,
          border: {
            width: 0,
            color: OUTER_BORDER_COLOR,
          },
        },
        {
          size: CORE_RADIUS / 2 + 16,
          locations: [
            {
              angle: Math.PI / 2,
              satellite: {
                type: 'metaInfo',
              },
            },
          ],
          virtual: true,
        },
      ],
      hoverable: true,
      locked: true,
    } as NodeAttributes<T>

    if (this.hovered) {
      attributes.fill = CORE_BACKGROUND_COLOR_HOVERED
    }

    if (this.highlighted) {
      attributes.orbits[0].border.width = OUTER_BORDER_WIDTH_HIGHLIGHTED
    }

    if (this.layers.demixLabel) {
      attributes.orbits[1].locations[0].satellite = {
        type: 'metaInfo',
        backgroundColor: 'rgba(231, 237, 249, 0.80)',
        borderRadius: 6,
        padding: [2, 6],
        items: (this.highlighted
          ? expandedItems
          : defaultItems) as unknown as MetaInfoSatellite['items'],
      }
    }

    return attributes
  }
}
