import { action, makeObservable, observable } from 'mobx'
import { getBoundedDomainBlock } from '../../../utils/getDomainBlock'
import {
  EventType,
  GraphEntityEvent,
  NodeClickPayload,
  NodeClickStrategyInterface,
} from '../GraphEntityEvent.types'
import type { TransactionAddressNode } from '../../../types/entities/TransactionAddressNode'
import type { INodesPositionController } from '../../NodesPositionController'
import type { Position } from '../../../types/Position'
import type { NodeData } from '../../../types/nodeEntitiesData/NodeData'
import type { EntityEventController } from './EntityEventController'
import type { CommentPinProbeNode } from '../../../types/entities/CommentPinProbeNode'
import type { TextProbeNode } from '@platform/components/ProbeSandbox/types/nodeEntitiesData/TextNodeData'
import type { EntityLinkingController } from '@platform/components/ProbeSandbox/vm/GraphEntityEvent/controllers/EntityLinkingController'
import type { SnapshotCommand } from '@platform/components/ProbeSandbox/types/history'
import type { IEntititiesGhosted } from '@platform/components/ProbeSandbox/models'
import { injectable, inject } from 'inversify'
import type { IProbeEvents } from '../../ProbeEvents'
import { DI_PROBE_TYPES } from '@platform/components/ProbeSandbox/di/DITypes'
import type { ProbeApp } from '@platform/components/ProbeSandbox/types/ProbeApp'
import type { ActiveEntityViewModel } from '../../active-entity/ActiveEntityViewModel'
import type { IPointerController } from '../../PointerController'
import type { IUtxoController } from '../../UtxoController'
import type { IProbeState } from '../../ProbeState'
import type { ProbeViewModelState } from '../../states/ProbeViewModelState'
import type { CameraViewModel } from '../../CameraViewModel'
import type { IGraphHistory } from '../../GraphHistory'
import type { ITransactionProbeNodeBase } from '@clain/graph-factory-entities'
import type { PositioningController } from '@clain/graph-entities/src/modules/PositioningController'
import { CrossChainSwapActionViewModel } from '../../CrossChainSwapActionViewModel'
import { DemixActionViewModel } from '../../DemixActionViewModel'
import { CommentsController } from '../../CommentsController'
import type { TextController } from '../../TextController'
import type { CircularMenuViewModel } from '../../CircularMenuViewModel'
import type { EntityDataToSnapshot } from '../../EntityDataToSnapshot'
import { getProbeModule } from '@platform/components/ProbeSandbox/di'

class NodeClickStrategy implements NodeClickStrategyInterface {
  constructor(
    private action: (payload: GraphEntityEvent<NodeClickPayload>) => void
  ) {}

  handle(payload: GraphEntityEvent<NodeClickPayload>): void {
    this.action(payload)
  }
}

const exoticNodes: NodeData['nodeType'][] = ['comment_pin', 'text']

@injectable()
export class NodeEventsController {
  @observable private mode: 'drag' | 'select' = 'select'
  @observable public mouseUpNodeKey: string
  private strategies: Record<string, NodeClickStrategy>

  constructor(
    @inject(DI_PROBE_TYPES.EntityDataToSnapshot)
    private entityDataToSnapshot: EntityDataToSnapshot,
    @inject(DI_PROBE_TYPES.PositioningController)
    public positioningController: PositioningController,
    @inject(DI_PROBE_TYPES.GraphHistory)
    private history: IGraphHistory,
    @inject(DI_PROBE_TYPES.CameraViewModel)
    private camera: CameraViewModel,
    @inject(DI_PROBE_TYPES.ProbeEvents)
    private probeEvents: IProbeEvents,
    @inject(DI_PROBE_TYPES.ProbeState) private probeState: IProbeState,
    @inject(DI_PROBE_TYPES.ProbeViewModelState)
    private probeViewModelState: ProbeViewModelState,
    @inject(DI_PROBE_TYPES.CircularMenuViewModel)
    private circularMenuController: CircularMenuViewModel,
    @inject(DI_PROBE_TYPES.ActiveEntityViewModel)
    private activeEntity: ActiveEntityViewModel,
    @inject(DI_PROBE_TYPES.EntityEventController)
    private entityEventController: EntityEventController,
    @inject(DI_PROBE_TYPES.EntititiesGhosted)
    private entitiesGhosted: IEntititiesGhosted,
    @inject(DI_PROBE_TYPES.EntityLinkingController)
    private entityLinkingController: EntityLinkingController,
    @inject(DI_PROBE_TYPES.UtxoController)
    private utxoController: IUtxoController,
    @inject(DI_PROBE_TYPES.DemixActionViewModel)
    private demixAction: DemixActionViewModel,
    @inject(DI_PROBE_TYPES.CrossChainSwapActionViewModel)
    private crossChainSwapAction: CrossChainSwapActionViewModel,
    @inject(DI_PROBE_TYPES.CommentsController)
    private commentsController: CommentsController,
    @inject(DI_PROBE_TYPES.TextController)
    private textController: TextController,
    @inject(DI_PROBE_TYPES.NodesPositionController)
    private nodesPositionController: INodesPositionController,
    @inject(DI_PROBE_TYPES.PointerController)
    private pointerController: IPointerController
  ) {
    makeObservable(this)
    this.strategies = {
      rightClick: new NodeClickStrategy(this.rightClick),
      leftClick: new NodeClickStrategy(() => this.activeEntity?.detectType()),
      nextUTXO: new NodeClickStrategy(({ payload }) =>
        this.handleSatelliteClick('nextUTXO', payload.id)
      ),
      prevUTXO: new NodeClickStrategy(({ payload }) =>
        this.handleSatelliteClick('prevUTXO', payload.id)
      ),
      demixAction: new NodeClickStrategy(({ payload }) => {
        const isOpenDemixPopup = this.handleSatelliteClick(
          'demixAction',
          payload.id
        )
        if (isOpenDemixPopup) return
        this.handleSatelliteClick('common', payload.id)
      }),
      crossChainAction: new NodeClickStrategy(({ payload }) => {
        this.handleSatelliteClick('crossChainAction', payload.id)
      }),
      expand: new NodeClickStrategy(({ payload }) => {
        this.toggleSelection(payload.id)
      }),
      nonExpand: new NodeClickStrategy(({ payload }) => {
        this.resetSelection(payload.id)
      }),
      common: new NodeClickStrategy(({ payload }) =>
        this.handleSatelliteClick('common', payload.id)
      ),
    }
  }

  private get app() {
    return getProbeModule<ProbeApp>(DI_PROBE_TYPES.ProbeApp)
  }

  @action
  private handleNodeDrag = (
    key: string,
    point: Position,
    isExpanding: boolean
  ) => {
    const changedPositions: Array<{ key: string; position: Position }> = []
    if (!this.probeState.selectedNodeIds.has(key)) {
      // Deselect other nodes if any
      if (
        (this.probeState.selectedNodeIds.size ||
          this.probeState.selectedEdgeIds.size) &&
        !isExpanding
      ) {
        this.entitiesGhosted.removeGhostedEntities()
        this.probeState.selectedNodeIds = new Set()
        this.probeState.selectedEdgeIds = new Set()
        this.activeEntity.detectType()
      }

      this.moveNode(key, point, changedPositions)
      const node = this.probeState.nodes.get(key)
      if (node.graphData().linkType === 'slave') {
        this.entityLinkingController.startLinkingProcess(key)
      }
    } else {
      const { position } = this.probeState.nodes.get(key)
      const offset = {
        x: point.x - position.x,
        y: point.y - position.y,
      }

      this.moveSelectedNodes(offset, changedPositions)
    }

    this.probeEvents.emit(
      changedPositions.map(({ key, position }) => ({
        type: 'update_position',
        key,
        data: { position },
      })),
      { optimistic: true }
    )
  }

  private moveNode = (
    key: string,
    point: Position,
    changedPositions: Array<{ key: string; position: Position }>
  ) => {
    if (!this.probeState.nodes.get(key)) {
      return
    }
    const node = this.probeState.nodes.get(key)
    let targetPosition = point

    if (
      this.probeViewModelState.isMagneticGridActive &&
      !exoticNodes.includes(node.type)
    ) {
      targetPosition = this.positioningController.spaceMatrixToWorldCoordinates(
        this.positioningController.worldCoordinatesToSpaceMatrix(point)
      )
    }

    const syncedPositions = this.entityLinkingController.getSyncedPositions(
      key,
      targetPosition
    )

    node.moveTo(targetPosition)
    syncedPositions.forEach(({ key: syncedKey, position }) => {
      this.probeState.nodes.get(syncedKey).moveTo(position)
    })

    changedPositions.push({ key, position: targetPosition }, ...syncedPositions)
  }

  private moveSelectedNodes = (
    offset: Position,
    changedPositions: Array<{ key: string; position: Position }>
  ) => {
    this.probeState.selectedNodeIds.forEach((nodeKey) => {
      if (!this.probeState.nodes.get(nodeKey)) {
        return
      }
      const probeNode = this.probeState.nodes.get(nodeKey)

      if (probeNode.graphData().linkType === 'slave') {
        const masterNodeKey =
          this.entityLinkingController.getMasterNodeKeyBySlaveNodeKey(nodeKey)
        if (this.probeState.selectedNodeIds.has(masterNodeKey)) {
          return
        }
      }

      const moveTo = {
        x: probeNode.position.x + offset.x,
        y: probeNode.position.y + offset.y,
      }

      this.moveNode(nodeKey, moveTo, changedPositions)
    })
  }

  @action
  private onNodeDrag = (
    position: Position,
    pointerRelativePosition: Position,
    isExpanding: boolean
  ) => {
    if (!this.probeState.nodes.has(this.probeViewModelState.mouseDownNodeKey)) {
      return
    }
    this.mode = 'drag'
    this.app.setMode('drag')

    if (
      exoticNodes.includes(
        this.probeState.nodes.get(this.probeViewModelState.mouseDownNodeKey)
          ?.data?.nodeType
      )
    ) {
      this.probeState.nodes
        .get(this.probeViewModelState.mouseDownNodeKey)
        .setInteractive(false)
    }
    // Need to do correlation between pointer position and node pivot position
    const pos = {
      x: position.x - pointerRelativePosition.x * this.camera.zoom,
      y: position.y - pointerRelativePosition.y * this.camera.zoom,
    }
    const worldPosition = this.app.toWorldCoordinates(pos)
    this.handleNodeDrag(
      this.probeViewModelState.mouseDownNodeKey,
      worldPosition,
      isExpanding
    )
  }

  @action
  private onNodeDragEnd = () => {
    if (!this.probeState.nodes.has(this.probeViewModelState.mouseDownNodeKey)) {
      return
    }
    this.positioningController.calculateSpaceMatrix()
    const node = this.probeState.nodes.get(
      this.probeViewModelState.mouseDownNodeKey
    )
    const positions = this.nodesPositionController.setNodePositionEndDrag(
      this.probeViewModelState.mouseDownNodeKey
    )

    const snapshotPositions = this.entityDataToSnapshot.nodesPositionToSnapshot(
      positions,
      this.nodesPositionController.getNodePositionStartDrag
    )

    if (node.graphData().linkType === 'slave' && this.mode === 'drag') {
      this.entityLinkingController.finishLinkingProcess(
        node.key,
        snapshotPositions
      )
    } else {
      const snapshotCommand: SnapshotCommand = [...snapshotPositions]
      const linkNodes = this.entityLinkingController.getSyncedNodesByNodeKey(
        node.key
      )
      const onDragNodePosition =
        this.nodesPositionController.getNodePositionStartDrag(node.key)
      linkNodes.forEach((linkNode) => {
        const diffX = positions[node.key].x - linkNode.position.x
        const diffY = positions[node.key].y - linkNode.position.y
        const df = this.entityDataToSnapshot.nodesPositionToSnapshot(
          { [linkNode.key]: linkNode.position },
          () => ({
            x: onDragNodePosition.x - diffX,
            y: onDragNodePosition.y - diffY,
          })
        )
        snapshotCommand.push(...df)
      })

      this.history.push(snapshotCommand)
    }

    if (
      node?.type === 'comment_pin' &&
      !this.commentsController.isPositioningInProgress
    ) {
      const commentPinNode = node as CommentPinProbeNode

      if (!commentPinNode.interactive) {
        commentPinNode.setInteractive(true)
      } else {
        this.commentsController.openComment(
          this.probeViewModelState.mouseDownNodeKey
        )
      }
    }

    if (
      node?.type === 'text' &&
      !this.textController.isPositionTextOnCanvasInProgress
    ) {
      const textNode = node as TextProbeNode
      if (!textNode.interactive) {
        textNode.setInteractive(true)
      } else {
        this.textController.activateTextNode(
          this.probeViewModelState.mouseDownNodeKey
        )
      }
    }

    this.probeViewModelState.mouseDownNodeKey = undefined
    this.mouseUpNodeKey = undefined
    this.mode = 'select'
    this.app.setMode('select')
  }

  private handleSatelliteClick = (satelliteId?: string, id?: string) => {
    switch (satelliteId) {
      case 'nextUTXO':
        this.utxoController.playNext(id!)
        return true
      case 'prevUTXO':
        this.utxoController.playPrev(id!)
        return true
      case 'demixAction':
        if (!(this.probeState.selectedNodeIds.size > 1)) {
          this.demixAction.openDemixTrackListPopup(id)
          return true
        }
        return false
      case 'crossChainAction':
        this.crossChainSwapAction.renderSwap(id)
        return true
      default:
        return false
    }
  }

  private openNodeMenu = ({
    payload,
    domEvent,
  }: GraphEntityEvent<NodeClickPayload>) => {
    const { id } = payload

    if (!this.probeState.nodes.has(id)) {
      return
    }

    const mouseDownNode = this.probeState.nodes.get(id)

    const coordinates =
      mouseDownNode.data.nodeType === 'text'
        ? this.app.toWorldCoordinates({
            x: domEvent.offsetX,
            y: domEvent.offsetY,
          })
        : mouseDownNode.position

    this.circularMenuController.open(coordinates, mouseDownNode.key)
  }

  private toggleSelection = (id: string) => {
    this.entitiesGhosted.toggleVisibleEntities({
      nodeKeys: [id],
      isExpanding: true,
    })

    const selectedNodeIds = this.probeState.selectedNodeIds
    const selectedEdgeIds = this.probeState.selectedEdgeIds
    const transactionBlock = getBoundedDomainBlock(this.app.graph, id)
    if (selectedNodeIds.has(id)) {
      selectedNodeIds.delete(id)
      if (transactionBlock) {
        transactionBlock.edgeKeys.forEach((key: any) =>
          selectedEdgeIds.delete(key)
        )
      }
    } else {
      selectedNodeIds.add(id)
      if (transactionBlock) {
        transactionBlock.edgeKeys.forEach((key: any) =>
          selectedEdgeIds.add(key)
        )
      }
    }
  }

  private resetSelection = (id: string) => {
    this.entitiesGhosted.toggleVisibleEntities({
      nodeKeys: [id],
      isExpanding: false,
    })
    const transactionBlock = getBoundedDomainBlock(this.app.graph, id)
    this.probeState.selectedNodeIds = new Set([id])
    this.probeState.selectedEdgeIds = transactionBlock
      ? new Set(transactionBlock.edgeKeys)
      : new Set()
  }

  private rightClick = (payload: GraphEntityEvent<NodeClickPayload>) => {
    this.activeEntity.hideActive({ hasKeyChanged: true })
    this.openNodeMenu(payload)
  }

  @action
  public onClick = (params: GraphEntityEvent<NodeClickPayload>) => {
    const {
      payload: { id, satelliteId, isExpanding },
      domEvent,
    } = params

    if (this.commentsController.isPositioningInProgress) return
    const isRightClick = domEvent.button === 2
    const isLeftClick = domEvent.button === 0

    const isSimpleLeftClick =
      isLeftClick &&
      !domEvent.shiftKey &&
      !domEvent.ctrlKey &&
      !domEvent.metaKey &&
      !domEvent.altKey

    const strategyKeys = []
    let eventType: EventType | null = null

    if (isRightClick && !this.probeState.selectedNodeIds.has(id)) {
      const forceExpanding =
        this.entityEventController.prevEvent !== 'rightClick'

      strategyKeys.push(isExpanding || forceExpanding ? 'expand' : 'nonExpand')
    }

    if (isLeftClick && !satelliteId) {
      strategyKeys.push(isExpanding ? 'expand' : 'nonExpand')
      eventType = 'leftClick'
    }

    if (satelliteId) {
      strategyKeys.push(satelliteId)
    }

    if (isSimpleLeftClick && !satelliteId) {
      strategyKeys.push('leftClick')
      eventType = 'leftClick'
    }

    if (isRightClick) {
      strategyKeys.push('rightClick')
      eventType = 'rightClick'
    }

    if (!strategyKeys.length) {
      this.strategies['common'].handle(params)
      return
    }

    strategyKeys.forEach((strategyKey) => {
      this.strategies[strategyKey]?.handle(params)
    })
    this.entityEventController.setPrevEvent(eventType)
  }

  @action
  public mouseOver = ({
    payload: { id, satelliteId, hoverable },
  }: GraphEntityEvent<NodeClickPayload>) => {
    const probeNode = this.probeState.nodes.get(id)

    if (satelliteId === 'nextUTXO') {
      ;(probeNode as TransactionAddressNode).setNextUTXOHovered(true)
    }

    if (satelliteId === 'prevUTXO') {
      ;(probeNode as TransactionAddressNode).setPrevUTXOHovered(true)
    }

    if (satelliteId === 'demixAction') {
      ;(
        probeNode as unknown as ITransactionProbeNodeBase
      ).setDemixActionHovered(true)
    }

    if (satelliteId === 'crossChainAction') {
      ;(
        probeNode as unknown as ITransactionProbeNodeBase
      ).setCrossSwapActionHovered(true)
    }

    if (hoverable) {
      probeNode.setHovered(true)
    }
  }

  @action
  public mouseOut = ({
    payload: { id, satelliteId, hoverable },
  }: GraphEntityEvent<NodeClickPayload>) => {
    const probeNode = this.probeState.nodes.get(id)

    if (satelliteId === 'nextUTXO') {
      ;(probeNode as TransactionAddressNode).setNextUTXOHovered(false)
    }

    if (satelliteId === 'prevUTXO') {
      ;(probeNode as TransactionAddressNode).setPrevUTXOHovered(false)
    }

    if (satelliteId === 'demixAction') {
      ;(
        probeNode as unknown as ITransactionProbeNodeBase
      ).setDemixActionHovered(false)
    }

    if (satelliteId === 'crossChainAction') {
      ;(
        probeNode as unknown as ITransactionProbeNodeBase
      ).setCrossSwapActionHovered(false)
    }

    if (hoverable) {
      probeNode.setHovered(false)
    }
  }

  @action
  public mouseDown = ({
    payload: { id, satelliteId, pointerRelativePosition, isExpanding },
  }: GraphEntityEvent<NodeClickPayload>) => {
    if (satelliteId === 'prevUTXO' || satelliteId === 'nextUTXO') return
    this.probeViewModelState.setMouseDownNodeKey(id)
    this.pointerController.addListener(
      (position) =>
        this.onNodeDrag(position, pointerRelativePosition, isExpanding),
      this.onNodeDragEnd
    )
    this.nodesPositionController.setNodePositionStartDrag(
      this.probeViewModelState.mouseDownNodeKey
    )
  }

  public mouseUp = ({
    payload: { id },
  }: GraphEntityEvent<NodeClickPayload>) => {
    this.mouseUpNodeKey = id
  }
}
