import React, { lazy } from 'react'
import { atom } from 'recoil'
import * as Sentry from '@sentry/react'
import { captureException, wrapCreateBrowserRouter } from '@sentry/react'
import { Navigate } from '@clain/core/Router/router'

import { getConfig } from '@clain/core/useConfig'
const config = getConfig()
import { initialCoin } from './utils/initialCoin'
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'
import {
  generateRedirectRoutes,
  RouteValidator,
} from '@clain/core/utils/routerUtils'
import Layout from '@clain/core/Layout'
import ErrorPage from '@clain/core/Router/ErrorPage'
import Root from './app'
import {
  probePathSchema,
  blockchainFilterPathSchema,
  blockchainFilterPath,
} from './constants'
import NotFound from '@clain/core/NotFound'

const env = config?.ENV
if (env !== 'development') {
  Sentry.init({
    dsn: 'https://458ce858fdc44590af66aa29b1d071fb@o252637.ingest.us.sentry.io/5413542',
    environment: `PROBE-${env}`,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.httpClientIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
      Sentry.replayCanvasIntegration(),
    ],
    tracePropagationTargets: [config?.PLATFORM_URL],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })
}

export const defaultParams = {
  coin: initialCoin,
  entity: 'txn',
}

export const paramsState = atom({
  key: 'paramsState',
  default: { coin: initialCoin },
})

const redirects = [
  {
    from: '/:currencyKey/dashboard',
    to: '/:coin/dashboard',
  },
  {
    from: '/:currencyKey/explorer',
    to: '/:coin/explorer',
  },
  {
    from: '/:currencyKey/explorer/blocks',
    to: '/:coin/explorer',
  },
  {
    from: '/:currencyKey/explorer/address/:id',
    to: '/:coin/address/:id',
  },
  {
    from: '/:currencyKey/explorer/block/:id',
    to: '/:coin/explorer/block/:id',
  },
  {
    from: '/:currencyKey/explorer/transaction/:id',
    to: '/:coin/explorer/transaction/:id',
  },
  {
    from: '/:currencyKey/clusters/:id',
    to: '/:coin/cluster/:id',
  },
  {
    from: '/:currencyKey/addresses/:hash',
    to: '/:coin/address/:hash',
  },
  {
    from: '/:currencyKey/favorites',
    to: '/:coin/favorites',
  },
  {
    from: '/:currencyKey/alerts',
    to: '/:coin/alerts',
  },
  {
    from: '/:coin/txn',
    to: `/:coin/${blockchainFilterPath}/txn`,
    preserveQuery: true,
  },
]

const redirectRoutes = generateRedirectRoutes(redirects)

const errorPage = <ErrorPage captureException={captureException} />

const sentryWrapper = wrapCreateBrowserRouter(
  createBrowserRouter
) as typeof createBrowserRouter

const withSuspense = (Component: React.ComponentType) => (
  <React.Suspense fallback={null}>
    <Component />
  </React.Suspense>
)

const PageCases = lazy(() => import('./pages/Cases'))
const PageDashboard = lazy(() => import('./pages/Dashboard'))
const PageGlobalSettings = lazy(() => import('./pages/GlobalSettings'))
const PageProfileSettings = lazy(() => import('./pages/ProfileSettings'))
const PageApiSettings = lazy(() => import('./pages/ApiSettings'))
const PageTeamSettings = lazy(() => import('./pages/TeamSettings'))
const PageBulkSearch = lazy(() => import('./pages/Search'))
const PageCase = lazy(() => import('./pages/Case'))
const PageChangelog = lazy(() => import('./pages/Changelog'))
const PageBlocks = lazy(() => import('./pages/Blocks'))
const PageBlock = lazy(() => import('./pages/Block'))
const PageExplorerTransaction = lazy(
  () => import('./pages/ExplorerTransaction')
)
const PageCluster = lazy(() => import('./pages/Cluster'))
const PageAddress = lazy(() => import('./pages/Address'))
const BlockchainFilterPage = lazy(() => import('./pages/BlockchainFilter'))
const ProbeSandboxPage = lazy(() => import('./pages/ProbeSandbox'))

const routes = sentryWrapper([
  {
    path: '/',
    element: withSuspense(Root),
    errorElement: errorPage,
    children: [
      {
        element: withSuspense(Layout),
        errorElement: errorPage,
        children: [
          {
            path: '*',
            element: withSuspense(NotFound),
          },
          ...redirectRoutes,
          {
            index: true,
            element: <Navigate to={'btc/dashboard'} replace />,
          },
          {
            path: ':coin/dashboard/',
            element: (
              <RouteValidator>{withSuspense(PageDashboard)}</RouteValidator>
            ),
          },
          {
            path: 'settings',
            children: [
              {
                index: true,
                element: withSuspense(() => <Navigate to={'global'} replace />),
              },
              {
                path: 'global',
                element: withSuspense(PageGlobalSettings),
              },
              {
                path: 'profile',
                element: withSuspense(PageProfileSettings),
              },
              {
                path: 'api',
                element: withSuspense(PageApiSettings),
              },
              {
                path: 'team',
                element: withSuspense(PageTeamSettings),
              },
            ],
          },
          {
            path: 'search',
            element: withSuspense(PageBulkSearch),
          },
          {
            path: 'cases',
            element: withSuspense(PageCases),
          },
          {
            path: 'cases/:id',
            element: withSuspense(PageCase),
          },
          {
            path: 'changelog',
            element: withSuspense(PageChangelog),
          },
          {
            path: ':coin/explorer',
            element: (
              <RouteValidator>{withSuspense(PageBlocks)}</RouteValidator>
            ),
          },
          {
            path: ':coin/explorer/block/:id',
            element: <RouteValidator>{withSuspense(PageBlock)}</RouteValidator>,
          },
          {
            path: ':coin/explorer/transaction/:id',
            element: (
              <RouteValidator>
                {withSuspense(PageExplorerTransaction)}
              </RouteValidator>
            ),
          },
          {
            path: ':coin/cluster/:id',
            element: (
              <RouteValidator>{withSuspense(PageCluster)}</RouteValidator>
            ),
          },
          {
            path: ':coin/address/:hash',
            element: (
              <RouteValidator>{withSuspense(PageAddress)}</RouteValidator>
            ),
          },
          {
            path: blockchainFilterPathSchema,
            element: (
              <RouteValidator>
                {withSuspense(BlockchainFilterPage)}
              </RouteValidator>
            ),
          },
        ],
      },
      {
        path: 'cases/:caseId/probes/:probeId',
        errorElement: <ErrorPage />,
        element: withSuspense(ProbeSandboxPage),
      },
      {
        path: probePathSchema,
        errorElement: <ErrorPage />,
        element: withSuspense(ProbeSandboxPage),
      },
    ],
  },
])

export default routes
