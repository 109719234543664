import { ContainerModule } from 'inversify'
import { DI_PROBE_TYPES } from '@platform/components/ProbeSandbox/di/DITypes'
import { EntityDataToSnapshot } from '../../vm/EntityDataToSnapshot'
import { GraphHistory } from '../../vm/GraphHistory/GraphHistory'
import { ApplySnapshot } from '../../vm/GraphHistory/ApplySnapshot'
import { RemoveSnapshots } from '../../vm/GraphHistory/RemoveSnapshots'

export const historyModule = new ContainerModule((bind) => {
  bind<GraphHistory>(DI_PROBE_TYPES.GraphHistory)
    .to(GraphHistory)
    .inSingletonScope()

  bind<ApplySnapshot>(DI_PROBE_TYPES.ApplySnapshot)
    .to(ApplySnapshot)
    .inSingletonScope()

  bind<RemoveSnapshots>(DI_PROBE_TYPES.RemoveSnapshots)
    .to(RemoveSnapshots)
    .inRequestScope()

  bind<EntityDataToSnapshot>(DI_PROBE_TYPES.EntityDataToSnapshot)
    .to(EntityDataToSnapshot)
    .inTransientScope()
})
