import http, { HttpResponse } from '@clain/core/http'
import { getConfig } from '@clain/core/useConfig'
const config = getConfig()
import { normalizeSnakeToCamelCase } from '@clain/core/utils/normalizeSnakeToCamelCase'
import { CoinType } from '../../types/coin'
import { TransactionsBlockSearch } from '../types'
import { TransactionSearchRequestParams } from './ExplorerService.types'
import { injectable } from 'inversify'

@injectable()
export class ExplorerService {
  public transactionsSearchRequest =
    (blockchain: CoinType) => (params: TransactionSearchRequestParams) =>
      http
        .post(
          `${config?.PLATFORM_API}/api/private/explorer/${blockchain}/cfd_search`,
          params
        )
        .then(normalizeSnakeToCamelCase) as Promise<
        HttpResponse<TransactionsBlockSearch>
      >

  public downloadCSVRequest =
    (blockchain: CoinType) => (params: TransactionSearchRequestParams) => {
      return http.post(
        `${config?.PLATFORM_API}/api/private/explorer/${blockchain}/cfd_search/csv`,
        params
      )
    }
}
