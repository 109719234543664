import { ContainerModule, interfaces } from 'inversify'

import {
  ITransactionPresetsAPIService,
  TransactionPresetsService,
} from '@platform/apiServices/transactionPresets'
import {
  IAddressesFilterAPIService,
  AddressesFilterService,
} from '@platform/apiServices/blockchainFilter/addresses'
import { DI_TYPES } from '@platform/di/DITypes'
import { CategoriesService, ExplorerService } from '@platform/apiServices'

const initializeModule = (bind: interfaces.Bind) => {
  bind<ITransactionPresetsAPIService>(DI_TYPES.TransactionFilterPresetsAPI).to(
    TransactionPresetsService
  )
  bind<IAddressesFilterAPIService>(DI_TYPES.AddressesFilterAPI)
    .to(AddressesFilterService)
    .inSingletonScope()
  bind<ExplorerService>(DI_TYPES.ExplorerService)
    .to(ExplorerService)
    .inSingletonScope()
  bind<CategoriesService>(DI_TYPES.CategoriesService)
    .to(CategoriesService)
    .inSingletonScope()
}

export const apiModules = new ContainerModule(initializeModule)
