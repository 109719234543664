import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  EllipsisTypography,
  SearchEntities,
  SearchEntitiesUtils,
} from '@clain/core/ui-kit'
import { useNavigate } from '@clain/core/Router/router'
import {
  ActiveKeyboardContextType,
  EntityType,
} from '@caudena/web-platform/dist/components/SearchEntities/constants'
import { useResults } from './useResults'
import {
  blockchains,
  filterShortcutRegex,
  useSearchEntitiesTranslation,
} from './SearchEntities.constants'
import { isEthAddress } from '@clain/core/utils'
import { getEntityUrl } from './SearchEntities.utils'
import { useSearchEntitiesStateSetter } from '@clain/core/state'
import { SearchResultActions } from './SearchResultActions'
import {
  CoinTypeAll,
  SearchActionButtonType,
  SearchResult,
} from './SearchEntities.types'
import { useSearchConfig } from './useSearchConfig'
import { ColDeprecated } from '@clain/core/ui-kit'
import classnames from 'classnames/bind'
import styles from './SearchEntities.styles.scss'
import { useTranslation } from 'react-i18next'
import { getItemName } from '@caudena/web-platform/dist/components/SearchEntities/utils'
import { AddressSearchResult } from '../ProbeSandbox/vm/services/SearchService/SearchService.types'
const cx = classnames.bind(styles)

export const SearchEntitiesModal = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const setIsSearchEntitiesModalOpen = useSearchEntitiesStateSetter()
  const [selectedFilter, setSelectedFilter] = useState<CoinTypeAll>('all')
  const [searchText, setSearchText] = useState('')
  const { initialPlaceholder } = useSearchEntitiesTranslation()
  const [placeholder, setPlaceholder] = useState(initialPlaceholder)
  const activeButtonRef = useRef<HTMLButtonElement | null>(null)
  const inputRef = useRef<HTMLInputElement | null>(null)

  const { queryParams, isShowPlaceholderHint, isValidForSearch } =
    useSearchConfig(searchText, selectedFilter)
  const { results, resultsCount, isFirstLoading, isSearching } = useResults({
    queryParams,
    isValidForSearch,
  })

  const [activeContext, setActiveContext] =
    useState<ActiveKeyboardContextType>('searchResults')

  const {
    activeButton,
    activeGroup,
    activeItem,
    setActiveGroup,
    setActiveButton,
    setActiveItem,
    resetActiveItem,
    isMouseActive,
    setIsMouseActive,
  } = SearchEntitiesUtils.useSearchResultsKeyboardShortcuts(
    results,
    activeButtonRef,
    activeContext
  )
  const { blockchainIcons, filterListData } =
    SearchEntitiesUtils.useGetFilteredBlockchainsData(['all', ...blockchains])

  const onChangeSearchText = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchText = event.target.value
    setActiveGroup(null)
    setSearchText(newSearchText)
    const isEthAddressSymbols =
      isEthAddress(newSearchText) && newSearchText.length >= 3 ? 6 : 3

    setPlaceholder(
      t('platform:searchSymbolsRemaining', '', {
        count: isEthAddressSymbols - newSearchText.length,
        context:
          isEthAddress(newSearchText) && newSearchText.length >= 3
            ? 'address'
            : 'default',
      })
    )
  }

  useEffect(() => {
    if (searchText === '') {
      setPlaceholder(t('platform:searchMinSymbols'))
    }
    resetActiveItem()
  }, [searchText, t])

  const onSearchInputKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === ' ') {
      const match = searchText.trim().match(filterShortcutRegex)
      if (
        match &&
        setSelectedFilter &&
        [...blockchains, 'all'].includes(match[1] as CoinTypeAll)
      ) {
        event.preventDefault()
        setSearchText(match[2] || '')
        setSelectedFilter(match[1] as CoinTypeAll)
      }
    }
    if (event.key === 'ArrowUp') {
      event.preventDefault()
    }
  }

  const handleHoverSearchResultItem = (
    groupIdx: number,
    itemIdx: number,
    activeButtonIdx: number,
    isMouseMoveActive = true
  ) => {
    if (
      groupIdx === activeGroup &&
      itemIdx === activeItem &&
      activeButtonIdx === activeButton &&
      isMouseMoveActive
    )
      return
    setIsMouseActive(isMouseMoveActive)
    setActiveGroup(groupIdx)
    setActiveItem(itemIdx)
    setActiveButton(activeButtonIdx)
  }

  const handleSelectFilter = (filter: CoinTypeAll) => {
    resetActiveItem()
    setSelectedFilter(filter)
    inputRef.current?.focus()
  }
  const handleCloseFilterChip = useCallback(() => {
    resetActiveItem()
    setSelectedFilter('all')
    inputRef.current?.focus()
  }, [resetActiveItem])
  const handleClickActionButton = useCallback(
    (
      type: SearchActionButtonType,
      entityType: EntityType,
      value: SearchResult
    ) => {
      setIsSearchEntitiesModalOpen(false)
      const link = getEntityUrl(type, entityType, value)
      navigate(link)
    },
    []
  )
  const handleClickSearchItem = () => {
    activeButtonRef.current?.click()
  }
  const bodyState = {
    showSkeleton: <SearchEntities.Skeleton />,
    showNotFound: <SearchEntities.NotFound />,
    showSearchResults: (
      <SearchEntities.Results
        results={results}
        blockchainIcons={blockchainIcons}
        onClickSearchItem={handleClickSearchItem}
        activeGroup={activeGroup}
        isMouseActive={isMouseActive}
        activeItem={activeItem}
        onHoverSearchItem={handleHoverSearchResultItem}
        centerSlot={({ item, entityType }) =>
          entityType === 'address' ? (
            <EllipsisTypography
              as="span"
              variant="body100Normal"
              color="onBackgroundVariant1"
              type="end"
            >
              {`${
                (item as AddressSearchResult)?.name ||
                (item as AddressSearchResult)?.clusterId
              }`}
            </EllipsisTypography>
          ) : null
        }
        rightSlot={(props) => (
          <SearchResultActions
            {...props}
            activeButton={activeButton}
            activeGroup={activeGroup}
            activeItem={activeItem}
            activeButtonRef={activeButtonRef}
            isMouseActive={isMouseActive}
            onHoverActiveButton={handleHoverSearchResultItem}
            onClickActionButton={handleClickActionButton}
          />
        )}
      />
    ),
    showInitial: (
      <div className={cx('InformationPanel')}>
        <SearchEntities.InformationPanel />
      </div>
    ),
  }

  const getBody = useCallback(() => {
    if (isFirstLoading && !resultsCount && !isSearching) {
      return bodyState.showInitial
    }
    if ((isFirstLoading && !resultsCount) || (isSearching && !resultsCount)) {
      return bodyState.showSkeleton
    }
    if (resultsCount) {
      return bodyState.showSearchResults
    }
    return bodyState.showNotFound
  }, [
    bodyState.showInitial,
    bodyState.showNotFound,
    bodyState.showSearchResults,
    bodyState.showSkeleton,
    isFirstLoading,
    isSearching,
    resultsCount,
  ])

  return (
    <SearchEntities.Panel>
      <ColDeprecated gap={0.2}>
        <div>
          <SearchEntities.Header>
            <SearchEntities.SearchInput
              autoFocus
              inputRef={inputRef}
              isLoading={isSearching}
              leftSlot={
                selectedFilter !== 'all' ? (
                  <SearchEntities.Chip
                    icon={blockchainIcons[selectedFilter]}
                    onClose={handleCloseFilterChip}
                  />
                ) : null
              }
              searchText={searchText}
              onKeyDown={onSearchInputKeyDown}
              onChange={onChangeSearchText}
              placeholder={placeholder}
              isValidForSearch={isShowPlaceholderHint}
            />
            <SearchEntities.Filter
              setActiveKeyboardContext={setActiveContext}
              selectedValue={selectedFilter}
              onSelect={handleSelectFilter}
              data={filterListData}
            />
          </SearchEntities.Header>
          <SearchEntities.Body>{getBody()}</SearchEntities.Body>
        </div>
        <SearchEntities.ShortcutInfoPanel />
      </ColDeprecated>
    </SearchEntities.Panel>
  )
}
