import { ContainerModule } from 'inversify'
import { DI_PROBE_TYPES } from '@platform/components/ProbeSandbox/di/DITypes'
import { ProbeService } from '../../vm/services/ProbeService'
import { EntityServices } from '../../vm/services/EntitiesServices/EntityServices'
import { SearchService } from '../../vm/services/SearchService/SearchService'
import { AIReportService } from '../../vm/services/AIReportService'

const getOrCreateProbeService = (() => {
  let cachedProbeId: string | null = null
  let cachedProbeService: ProbeService | null = null

  return (probeId: string): ProbeService => {
    if (probeId === cachedProbeId && cachedProbeService) {
      return cachedProbeService
    }

    cachedProbeId = probeId
    cachedProbeService = new ProbeService(probeId)
    return cachedProbeService
  }
})()

export const serviceModule = new ContainerModule((bind) => {
  bind<number>(DI_PROBE_TYPES.ProbeId)
    .toDynamicValue(() => null)
    .inTransientScope()

  bind<ProbeService>(DI_PROBE_TYPES.ProbeService)
    .toDynamicValue((context) => {
      const probeId = context.container.get<string>(DI_PROBE_TYPES.ProbeId)
      if (!probeId) return null
      return getOrCreateProbeService(probeId)
    })
    .inRequestScope()

  bind(DI_PROBE_TYPES.ProbeServiceProvider).toProvider<ProbeService>(
    (context) => {
      return async () => {
        const probeService = context.container.get<ProbeService>(
          DI_PROBE_TYPES.ProbeService
        )
        if (!probeService) return null
        return probeService
      }
    }
  )

  bind<EntityServices>(DI_PROBE_TYPES.EntityServices)
    .to(EntityServices)
    .inSingletonScope()

  bind<SearchService>(DI_PROBE_TYPES.SearchService)
    .to(SearchService)
    .inSingletonScope()

  bind<AIReportService>(DI_PROBE_TYPES.AIReportService)
    .to(AIReportService)
    .inSingletonScope()
})
