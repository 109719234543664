import { Container } from 'inversify'
import {
  apiModules,
  categoriesModule,
  probeGraphModules,
} from '@platform/di/modules'
import { ModuleStage } from '@clain/core/utils'

const rootContainer = new Container()

rootContainer.load(apiModules)
rootContainer.load(probeGraphModules)
rootContainer.load(categoriesModule)

export const moduleStage = new ModuleStage(rootContainer)

export const getModule = <T>(key: symbol) => {
  return rootContainer.get<T>(key)
}

export { rootContainer }
