import { inject, injectable } from 'inversify'
import { action, computed, makeObservable, observable } from 'mobx'
import { equals } from 'ramda'
import { ServerCamera } from '../types/serverData/ServerCamera'
import {
  DEFAULT_X_COORDINATE,
  DEFAULT_Y_COORDINATE,
  DEFAULT_ZOOM,
} from './ProbeViewModel'
import type { DeepPartial } from '@clain/core/types'
import { DI_PROBE_TYPES } from '@platform/components/ProbeSandbox/di/DITypes'
import { ProbeApp } from '../types/ProbeApp'
import { ProbeService } from './services/ProbeService'
import { getProbeModule } from '../di'

@injectable()
export class CameraViewModel {
  @observable private _x: number = DEFAULT_X_COORDINATE
  @observable private _y: number = DEFAULT_Y_COORDINATE
  private _prev_x: number = DEFAULT_X_COORDINATE
  private _prev_y: number = DEFAULT_Y_COORDINATE
  @observable private _zoom: number = DEFAULT_ZOOM
  private probeService: ProbeService = null

  constructor(
    @inject(DI_PROBE_TYPES.ProbeServiceProvider)
    private probeServiceProvider: () => Promise<ProbeService>
  ) {
    makeObservable(this)
  }

  private get app() {
    return getProbeModule<ProbeApp>(DI_PROBE_TYPES.ProbeApp)
  }

  @computed public get x() {
    this.getCoordinates()
    return this._x
  }

  @computed public get y() {
    this.getCoordinates()
    return this._y
  }

  @computed public get zoom() {
    return this._zoom
  }

  @action
  public init = async (settings: DeepPartial<ServerCamera>) => {
    const zoom = settings?.zoom || DEFAULT_ZOOM
    const x = settings?.position?.x || DEFAULT_X_COORDINATE
    const y = settings?.position?.y || DEFAULT_Y_COORDINATE

    this._x = x
    this._y = y
    this._prev_x = x
    this._prev_y = y
    this._zoom = zoom

    if (settings?.position) {
      this.app.setWordPosition(x, y)
      this.app.setWordZoom(zoom)
    } else {
      const probeService = await this.probeServiceProvider()
      if (!probeService) return

      probeService.updateCamera({
        position: { x, y },
        zoom: zoom,
      })
    }
  }

  @action
  public setCoordinates = (x: number, y: number) => {
    this._x = x
    this._y = y
  }

  @action
  public setZoom = async (zoom: number) => {
    if (zoom !== this.zoom) {
      this._zoom = zoom
      const probeService = await this.probeServiceProvider()
      if (!probeService) return

      probeService.updateCamera({
        position: { x: this.x, y: this.y },
        zoom: this.zoom,
      })
    }
  }

  @action
  public getCoordinates = () => {
    const { x, y } = this.app.worldInstance.center
    this.setCoordinates(x, y)
  }

  @action
  public getZoom = () => {
    return this.app?.worldInstance?.scale?._x
  }

  @computed
  public get currentCamera(): ServerCamera {
    return {
      position: { x: this.x, y: this.y },
      zoom: this.zoom,
    }
  }

  @action
  public updateCamera = async () => {
    const probeService = await this.probeServiceProvider()
    if (!probeService) return

    if (
      !equals(this.currentCamera.position, { x: this._prev_x, y: this._prev_y })
    ) {
      probeService.updateCamera({
        position: { x: this.x, y: this.y },
        zoom: this.zoom,
      })

      this._prev_x = this.x
      this._prev_y = this.y
    }
  }
}
