import { Coordinates, NodeGroup, Position } from '../types'

export const getGraphBoundingRectByNodeGroup = <T extends NodeGroup>(
  nodes: T,
  getPosition: (nodeKey: T[number]) => Position
): Coordinates => {
  const result = {
    top: Infinity,
    right: -Infinity,
    bottom: -Infinity,
    left: Infinity,
  }

  nodes.forEach((key) => {
    const { x, y } = getPosition(key)

    result.top = Math.min(result.top, y)
    result.right = Math.max(result.right, x)
    result.bottom = Math.max(result.bottom, y)
    result.left = Math.min(result.left, x)
  })

  return result
}

export const getGraphBoundingRectByPositions = (
  positions: Array<{ x: number; y: number }>
): Coordinates => {
  const result = {
    top: Infinity,
    right: -Infinity,
    bottom: -Infinity,
    left: Infinity,
  }
  positions.forEach(({ x, y }) => {
    result.top = Math.min(result.top, y)
    result.right = Math.max(result.right, x)
    result.bottom = Math.max(result.bottom, y)
    result.left = Math.min(result.left, x)
  })

  return result
}
