import { ContainerModule } from 'inversify'
import { DI_PROBE_TYPES } from '@platform/components/ProbeSandbox/di/DITypes'
import { ProbeViewModel } from '../../vm/ProbeViewModel'
import { CameraViewModel } from '../../vm/CameraViewModel'
import { LayersViewModel } from '../../vm/LayersViewModel'
import { CircularMenuViewModel } from '../../vm/CircularMenuViewModel'
import { ExtendedLayoutPanelViewModel } from '../../vm/_ExtendedLayoutPanelViewModel'
import { SettingsViewModel } from '@platform/modules/settings'
import { ctx } from '@platform/ctx'
import { DemixActionViewModel } from '../../vm/DemixActionViewModel'
import { CrossChainSwapActionViewModel } from '../../vm/CrossChainSwapActionViewModel'

export const viewModelModule = new ContainerModule((bind) => {
  bind<ProbeViewModel>(DI_PROBE_TYPES.ProbeViewModel)
    .to(ProbeViewModel)
    .inSingletonScope()

  bind<CameraViewModel>(DI_PROBE_TYPES.CameraViewModel)
    .to(CameraViewModel)
    .inSingletonScope()

  bind<LayersViewModel>(DI_PROBE_TYPES.LayersViewModel)
    .to(LayersViewModel)
    .inSingletonScope()

  bind<CircularMenuViewModel>(DI_PROBE_TYPES.CircularMenuViewModel)
    .to(CircularMenuViewModel)
    .inSingletonScope()

  bind<ExtendedLayoutPanelViewModel>(
    DI_PROBE_TYPES.ExtendedLayoutPanelViewModel
  )
    .to(ExtendedLayoutPanelViewModel)
    .inSingletonScope()

  bind<SettingsViewModel>(DI_PROBE_TYPES.Settings)
    .toDynamicValue(() => new SettingsViewModel(ctx))
    .inSingletonScope()

  bind<DemixActionViewModel>(DI_PROBE_TYPES.DemixActionViewModel)
    .to(DemixActionViewModel)
    .inSingletonScope()

  bind<CrossChainSwapActionViewModel>(
    DI_PROBE_TYPES.CrossChainSwapActionViewModel
  )
    .to(CrossChainSwapActionViewModel)
    .inSingletonScope()
})
