import { Container } from 'inversify'

import * as modules from './modules'
import { DI_PROBE_TYPES } from './DITypes'
import { ProbeViewModel } from '../vm/ProbeViewModel'

const probeContainer = new Container()

Object.values(modules).forEach((module) => probeContainer.load(module))

export const getProbeModule = <T>(key: symbol): T => {
  return probeContainer.get<T>(key)
}

const getProbeVM = () => {
  return probeContainer.get<ProbeViewModel>(DI_PROBE_TYPES.ProbeViewModel)
}

const useProbeVM = () => {
  return getProbeVM()
}

export { probeContainer, getProbeVM, useProbeVM }
