import { ContainerModule } from 'inversify'
import { DI_PROBE_TYPES } from '@platform/components/ProbeSandbox/di/DITypes'
import {
  IAlertController,
  IRearrangeNodesController,
  RearrangeNodesController,
  AlertController,
} from '../../vm/controllers'
import {
  IDeleteEntityController,
  DeleteEntityController,
} from '../../vm/DeleteEntityController'
import { PositioningController as PositioningControllerModule } from '@clain/graph-entities/src/modules/PositioningController'
import { EntityLinkingController } from '../../vm/GraphEntityEvent/controllers/EntityLinkingController'
import { TextController } from '../../vm/TextController'
import { TextNodeController } from '../../vm/TextController/TextNodeController'
import { TextAreaController } from '../../vm/TextController/TextAreaController'
import { ShortcutMenuController } from '../../vm/shortcut-menu/ShortcutMenuController'
import { SearchController } from '../../vm/SearchController'
import { UtxoController } from '../../vm/UtxoController'
import {
  IPaletteController,
  PaletteController,
} from '../../vm/PaletteController'
import {
  IPlotParentController,
  PlotParentController,
} from '../../vm/PlotParentController'
import {
  INodesPositionController,
  NodesPositionController,
} from '../../vm/NodesPositionController'
import {
  IPointerController,
  PointerController,
} from '../../vm/PointerController'
import { PlotEntitiesController } from '../../vm/PlotEntitiesController'
import { IEntititiesGhosted, IPlotEntities } from '../../models'
import {
  ILayoutController,
  LayoutController,
  PositioningController,
} from '@clain/graph-entities'
import { CircularMenuEdges } from '../../vm/CircularMenuViewModel/CircularMenuEdges'
import { CircularMenuEntity } from '../../types/CircularMenu'
import {
  CircularMenuNodes,
  CircularMenuWorld,
} from '../../vm/CircularMenuViewModel'
import { IProbeGraph } from '../../vm/ProbeGraph'
import { EntititiesGhosted } from '../../vm/EntititiesGhosted'
import { EntityEventController } from '../../vm/GraphEntityEvent'
import { CommentsController } from '../../vm/CommentsController'
import {
  IAnimationEntities,
  AnimationEntities,
} from '../../vm/AnimationEntities'
import { AlertsViewModel } from '@platform/modules/alerts/AlertsViewModel'
import { ctx } from '@platform/ctx'

export const controllerModule = new ContainerModule((bind) => {
  bind<IPlotEntities>(DI_PROBE_TYPES.PlotEntitiesController)
    .to(PlotEntitiesController)
    .inSingletonScope()

  bind<EntityLinkingController>(DI_PROBE_TYPES.EntityLinkingController)
    .to(EntityLinkingController)
    .inSingletonScope()

  bind<TextController>(DI_PROBE_TYPES.TextController)
    .to(TextController)
    .inSingletonScope()

  bind<TextNodeController>(DI_PROBE_TYPES.TextNodeController)
    .to(TextNodeController)
    .inSingletonScope()

  bind<TextAreaController>(DI_PROBE_TYPES.TextAreaController)
    .to(TextAreaController)
    .inSingletonScope()

  bind<ShortcutMenuController>(DI_PROBE_TYPES.ShortcutMenuController)
    .to(ShortcutMenuController)
    .inSingletonScope()

  bind<SearchController>(DI_PROBE_TYPES.SearchController)
    .to(SearchController)
    .inSingletonScope()

  bind<UtxoController>(DI_PROBE_TYPES.UtxoController)
    .to(UtxoController)
    .inSingletonScope()

  bind<IPaletteController>(DI_PROBE_TYPES.PaletteController)
    .to(PaletteController)
    .inSingletonScope()

  bind<IPlotParentController>(DI_PROBE_TYPES.PlotParentController)
    .to(PlotParentController)
    .inSingletonScope()

  bind<IAlertController>(DI_PROBE_TYPES.AlertController)
    .to(AlertController)
    .inSingletonScope()

  bind<AlertsViewModel>(DI_PROBE_TYPES.AlertsViewModel)
    .toDynamicValue(() => new AlertsViewModel(ctx))
    .inSingletonScope()

  bind<INodesPositionController>(DI_PROBE_TYPES.NodesPositionController)
    .to(NodesPositionController)
    .inSingletonScope()

  bind<IRearrangeNodesController>(DI_PROBE_TYPES.RearrangeNodesController)
    .to(RearrangeNodesController)
    .inSingletonScope()

  bind<IDeleteEntityController>(DI_PROBE_TYPES.DeleteEntityController).to(
    DeleteEntityController
  )

  bind<IPointerController>(DI_PROBE_TYPES.PointerController)
    .to(PointerController)
    .inSingletonScope()

  bind<PositioningControllerModule>(
    DI_PROBE_TYPES.PositioningController
  ).toDynamicValue((context) =>
    new PositioningController({
      entitiesState: context.container.get(DI_PROBE_TYPES.ProbeState),
    }).getInstance()
  )

  bind<CircularMenuEntity>(DI_PROBE_TYPES.CircularMenuEdges)
    .to(CircularMenuEdges)
    .inSingletonScope()

  bind<CircularMenuEntity>(DI_PROBE_TYPES.CircularMenuNodes)
    .to(CircularMenuNodes)
    .inSingletonScope()

  bind<CircularMenuWorld>(DI_PROBE_TYPES.CircularMenuWorld)
    .to(CircularMenuWorld)
    .inSingletonScope()

  bind<ILayoutController>(DI_PROBE_TYPES.LayoutController)
    .toDynamicValue(
      (context) =>
        new LayoutController(
          context.container.get<IProbeGraph>(DI_PROBE_TYPES.ProbeGraph)
        )
    )
    .inSingletonScope()

  bind<IEntititiesGhosted>(DI_PROBE_TYPES.EntititiesGhosted)
    .to(EntititiesGhosted)
    .inSingletonScope()

  bind<EntityEventController>(DI_PROBE_TYPES.EntityEventController)
    .to(EntityEventController)
    .inSingletonScope()

  bind<CommentsController>(DI_PROBE_TYPES.CommentsController)
    .to(CommentsController)
    .inSingletonScope()

  bind<IAnimationEntities>(DI_PROBE_TYPES.AnimationEntities)
    .to(AnimationEntities)
    .inSingletonScope()
})
