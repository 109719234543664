import http, { HttpResponse } from '@clain/core/http'
import { getConfig } from '@clain/core/useConfig'
const config = getConfig()
import { injectable } from 'inversify'
import { RawCategoriesResponse } from './CategoriesService.types'

@injectable()
export class CategoriesService {
  public getCategories = () => {
    return http
      .get(`${config?.PLATFORM_API}/api/private/categories`)
      .then() as Promise<HttpResponse<RawCategoriesResponse>>
  }
}
