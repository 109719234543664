import { ContainerModule, interfaces } from 'inversify'
import { DI_PROBE_TYPES } from '@platform/components/ProbeSandbox/di/DITypes'
import type { IAutoSelectTransaction } from '../../models'
import { AutoSelectTransaction } from '../../vm/AutoSelectTransaction'
import type {
  AddressesRequestParams,
  CounterpartiesRequestParams,
  OsintsRequestParams,
  TokensRequestParamsAddress,
  TokenByAddressRequestParamsAddress,
  TransactionsRequestParams,
} from '../../vm/services/EntitiesServices/types'
import type { ClusterTransactions } from '../../types/converted/ClusterTransactions'
import { ActiveEntityFetchState } from '../../vm/active-entity/ActiveEntityFetchState'
import type {
  TransactionActiveEntityFetchFacade,
  CounterpartiesActiveEntityFetchFacade,
  AddressesActiveEntityFetchFacade,
  OsintsActiveEntityFetchFacade,
  TokensActiveEntityFetchFacade,
  TokenByAddressActiveEntityFetchFacade,
} from '@platform/components/ProbeSandbox/vm'
import type { AddressCounterparties } from '../../types/converted/AddressCounterparties'
import type { ClusterCounterparties } from '../../types/converted/ClusterCounterparties'
import type { Osints } from '../../types/converted/Osints'
import type { ClusterAddresses } from '../../types/converted/ClusterAddresses'
import type { Tokens } from '../../types/converted/Tokens'
import type { Token } from '@platform/components/ProbeSandbox/types/converted/TokenBalance'
import {
  ActiveEntityEvents,
  IActiveEntityEvents,
} from '../../vm/active-entity/ActiveEntityEvents'
import { ActiveEntityViewModel } from '../../vm/active-entity/ActiveEntityViewModel'
import { ActiveEntityFetchFacade } from '../../vm/active-entity/ActiveEntityFetchFacade/ActiveEntityFetchFacade'
import { ActiveEntityState } from '../../vm/states/ActiveEntityState'

const initializeModule = (bind: interfaces.Bind) => {
  bindEntitiesModule(bind)
  bindEntitiesStatesModule(bind)
}

const bindEntitiesModule = (bind: interfaces.Bind) => {
  bind(DI_PROBE_TYPES.ActiveEntityState)
    .to(ActiveEntityState)
    .inSingletonScope()

  bind<IAutoSelectTransaction>(DI_PROBE_TYPES.AutoSelectTransaction)
    .to(AutoSelectTransaction)
    .inSingletonScope()

  bind<IActiveEntityEvents>(DI_PROBE_TYPES.ActiveEntityEvents)
    .to(ActiveEntityEvents)
    .inRequestScope()

  bind<ActiveEntityViewModel>(DI_PROBE_TYPES.ActiveEntityViewModel)
    .to(ActiveEntityViewModel)
    .inRequestScope()
}

const bindEntitiesStatesModule = (bind: interfaces.Bind) => {
  bind<TransactionActiveEntityFetchFacade>(
    DI_PROBE_TYPES.TransactionsFetchStateFacade
  )
    .toDynamicValue(() => {
      const state = new ActiveEntityFetchState<ClusterTransactions>()
      return new ActiveEntityFetchFacade<
        TransactionsRequestParams,
        ClusterTransactions
      >(state)
    })
    .inRequestScope()

  bind<CounterpartiesActiveEntityFetchFacade>(
    DI_PROBE_TYPES.CounterpartiesFetchStateFacade
  )
    .toDynamicValue(() => {
      const state = new ActiveEntityFetchState<
        ClusterCounterparties | AddressCounterparties
      >()
      return new ActiveEntityFetchFacade<
        CounterpartiesRequestParams,
        ClusterCounterparties | AddressCounterparties
      >(state)
    })
    .inRequestScope()

  bind<AddressesActiveEntityFetchFacade>(
    DI_PROBE_TYPES.AddressesFetchStateFacade
  )
    .toDynamicValue(() => {
      const state = new ActiveEntityFetchState<ClusterAddresses>()
      return new ActiveEntityFetchFacade<
        AddressesRequestParams,
        ClusterAddresses
      >(state)
    })
    .inRequestScope()

  bind<OsintsActiveEntityFetchFacade>(DI_PROBE_TYPES.OsintsFetchStateFacade)
    .toDynamicValue(() => {
      const state = new ActiveEntityFetchState<Osints>()
      return new ActiveEntityFetchFacade<OsintsRequestParams, Osints>(state)
    })
    .inRequestScope()

  bind<TokensActiveEntityFetchFacade>(DI_PROBE_TYPES.TokensFetchStateFacade)
    .toDynamicValue(() => {
      const state = new ActiveEntityFetchState<Tokens>()
      return new ActiveEntityFetchFacade<TokensRequestParamsAddress, Tokens>(
        state
      )
    })
    .inRequestScope()

  bind<TokenByAddressActiveEntityFetchFacade>(
    DI_PROBE_TYPES.TokenByAddressFetchStateFacade
  )
    .toDynamicValue(() => {
      const state = new ActiveEntityFetchState<Token>()
      return new ActiveEntityFetchFacade<
        TokenByAddressRequestParamsAddress,
        Token
      >(state)
    })
    .inRequestScope()
}

export const probeActiveEntityModules = new ContainerModule(initializeModule)
