import { normalizeSnakeToCamelCase } from '@clain/core/utils/normalizeSnakeToCamelCase'
import wsState, { Channel } from '../../../utils/WebSocketWrapper'
import {
  IAddressesFilterAPIService,
  AddressesServerData,
  RawAddressesServerData,
  AddressesSearchRequestParams,
  AddressTokensSearchRequestParams,
} from './AddressesFilterService.types'
import { injectable } from 'inversify'
import { Token } from '@platform/apiServices'

const TIMEOUT_IN_MS = 60_000
const CHANNEL_KEY = 'address_filter:global'

@injectable()
export class AddressesFilterService implements IAddressesFilterAPIService {
  private channel: Channel

  public joinChannel = async () => {
    this.channel = wsState.channel(CHANNEL_KEY)
    new Promise<RawAddressesServerData>((resolve, reject) => {
      this.channel.join<RawAddressesServerData>().catch((error: string) => {
        reject(error)
      })
    })
  }

  public getAddresses = (data: AddressesSearchRequestParams) => {
    return new Promise<AddressesServerData>((resolve, reject) => {
      this.channel
        ?.push<RawAddressesServerData>('address', data, TIMEOUT_IN_MS)
        .then((rawAddressesData) => {
          resolve(normalizeSnakeToCamelCase(rawAddressesData))
        })
        .catch((error: string) => {
          reject(error)
        })
    })
  }

  public getTokens = (data: AddressTokensSearchRequestParams) => {
    return new Promise<Token[]>((resolve, reject) => {
      this.channel
        ?.push<Token[]>('tokens', data, TIMEOUT_IN_MS)
        .then((rawAddressesData) => {
          resolve(normalizeSnakeToCamelCase(rawAddressesData))
        })
        .catch((error: string) => {
          reject(error)
        })
    })
  }
}
