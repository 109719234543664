import { APIServiceStateViewModel } from '@clain/core/utils/mobxUtils'
import { inject, injectable } from 'inversify'
import { APIServiceStateFacade } from '@clain/core/utils/mobxUtils'
import { HttpResponse } from '@clain/core/http'
import type {
  CategoriesService,
  RawCategoriesResponse,
} from '@platform/apiServices'
import { DI_TYPES } from '@platform/di/DITypes'

@injectable()
export class CategoriesVMModule {
  private categoriesApiServiceState: APIServiceStateFacade<
    [],
    HttpResponse<RawCategoriesResponse>
  >
  constructor(
    @inject(DI_TYPES.CategoriesService)
    private categoriesService: CategoriesService
  ) {
    this.categoriesApiServiceState = new APIServiceStateFacade<
      [],
      HttpResponse<RawCategoriesResponse>
    >(new APIServiceStateViewModel())
    this.categoriesApiServiceState.injectRequestMethod(
      this.categoriesService.getCategories
    )
  }

  public fetchCategories = () => {
    return this.categoriesApiServiceState.request()
  }
  public get categoriesState() {
    return (
      this.categoriesApiServiceState.stateViewModel.state?.data?.data ||
      ([] as string[])
    )
  }

  public get categoriesError() {
    return this.categoriesApiServiceState.stateViewModel.error
  }

  public get categoriesStatus() {
    return this.categoriesApiServiceState.stateViewModel.status
  }

  public clear = () => {
    this.categoriesApiServiceState.stateViewModel.clearData()
  }
}
