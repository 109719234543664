import { inject, injectable } from 'inversify'
import { action, makeObservable, observable } from 'mobx'
import type {
  CircularMenuEntity,
  CircularMenuItemId,
  CircularMenuItemsType,
} from '../../../types/CircularMenu'
import type { EdgeType } from '../../../types/edgeEntitiesData/EdgeData'
import type {
  IAlertController,
  IRearrangeNodesController,
} from '../../controllers'
import type { IDeleteEntityController } from '../../DeleteEntityController'
import type { IPaletteController } from '../../PaletteController'
import type { IProbeState } from '../../ProbeState'
import type { ISearchState } from '../../states'
import { DEFAULT_RENDER_MENU_IDS } from './CircularMenuWorld.constants'
import { DI_PROBE_TYPES } from '@platform/components/ProbeSandbox/di/DITypes'

@injectable()
export class CircularMenuWorld implements CircularMenuEntity {
  private renderMenuIdsByEdgeType = {} as Partial<
    Record<EdgeType, CircularMenuItemId[]>
  >
  @observable public renderMenuIds: CircularMenuItemId[] =
    DEFAULT_RENDER_MENU_IDS

  constructor(
    @inject(DI_PROBE_TYPES.DeleteEntityController)
    private deleteEntityController: IDeleteEntityController,
    @inject(DI_PROBE_TYPES.PaletteController)
    private paletteController: IPaletteController,
    @inject(DI_PROBE_TYPES.ProbeState)
    private probeState: IProbeState,
    @inject(DI_PROBE_TYPES.RearrangeNodesController)
    private rearrangeNodesController: IRearrangeNodesController,
    @inject(DI_PROBE_TYPES.SearchState)
    private searchState: ISearchState,
    @inject(DI_PROBE_TYPES.AlertController)
    private alertController: IAlertController
  ) {
    makeObservable(this)
  }

  private getHideMenuIds = (ids: CircularMenuItemId[]) => {
    const excludedIds: CircularMenuItemId[] = []

    if (this.paletteController.hidePalette) {
      excludedIds.push('pickerColor')
    }

    if (
      !this.probeState.selectedEdgeIds.size &&
      !this.probeState.selectedNodeIds.size
    ) {
      excludedIds.push('delete')
    }

    if (this.probeState.selectedNodeIds.size <= 1) {
      excludedIds.push('rearrangeNodes')
    }

    if (
      this.probeState.selectedEdgeIds.size ||
      this.probeState.selectedNodeIds.size
    ) {
      excludedIds.push('addNode')
    }

    if (this.alertController.hideAddAlert) {
      excludedIds.push('addAlert')
    }

    if (!excludedIds.length) {
      return ids
    }

    return ids.filter((id) => !excludedIds.includes(id))
  }

  @action
  public init = (_: string) => {
    const renderMenuIds = this.getHideMenuIds(DEFAULT_RENDER_MENU_IDS)
    this.renderMenuIds = renderMenuIds

    return this.renderMenuIds
  }

  public proxyMenuItem = (
    id: CircularMenuItemId,
    item: CircularMenuItemsType
  ) => {
    const menuItems = {} as Record<
      CircularMenuItemId,
      Partial<CircularMenuItemsType>
    >

    if (this.alertController.disabled) {
      menuItems['addAlert'] = {
        tooltip: this.alertController.tooltip,
        disabled: true,
      }
    }

    if (!menuItems[id]) return item

    return { ...item, ...menuItems[id] } as CircularMenuItemsType
  }

  public onMenuClick = (item: CircularMenuItemsType) => {
    if (item.id === 'delete') {
      this.deleteEntityController.deleteActiveEntities()
    } else if (item.id === 'color') {
      this.paletteController.paintActiveEntities(item.value)
    } else if (item.id === 'restorePickerColor') {
      this.paletteController.restoreColorActiveEntities()
    } else if (item.id === 'rearrangeNodes') {
      this.rearrangeNodesController.run()
    } else if (item.id === 'addNode') {
      this.searchState.open()
    } else if (item.id === 'addAlert') {
      this.alertController.open()
    }
  }
}
