import type { UnsupportedCoinType } from '@caudena/web-platform/dist/types'
import {
  COINS,
  EVM_BLOCKCHAINS,
  EVM_COINS,
  UTXO_BLOCKCHAINS,
  UTXO_COINS,
} from '../utils/currency'
import { isObject } from '../utils/isObject'

export type CoinType = 'btc' | 'eth' | 'doge' | 'ltc' | 'trx' | 'bnb'

export type { UnsupportedCoinType } from '@clain/core/ui-kit'

export type AllCoinType = CoinType | UnsupportedCoinType

export type BlockchainType = Uppercase<CoinType>

export type SupportedCoin<T extends AllCoinType> = Extract<T, CoinType>

export type UnsupportedCoin<T extends AllCoinType> = Exclude<T, CoinType>

export type CoinTypeUTXO<T extends CoinType> = Exclude<T, 'eth' | 'trx' | 'bnb'>
export type BlockchainTypeUTXO<T extends BlockchainType> = Exclude<
  T,
  'ETH' | 'TRX' | 'BNB'
>

export type CoinTypeEVM<T extends CoinType> = Exclude<T, CoinTypeUTXO<T>>
export type BlockchainTypeEVM<T extends BlockchainType> = Exclude<
  T,
  BlockchainTypeUTXO<T>
>

//// Type guards ////
type ArgIsCurrency = { currency: CoinType } | CoinType
type ArgIsAllCurrency = { currency: AllCoinType } | AllCoinType
type ArgIsBlockchain = { blockchain: BlockchainType } | BlockchainType

export function isUTXO<T extends CoinType>(coin: T): coin is CoinTypeUTXO<T>

export function isUTXO<
  T extends { currency: CoinTypeEVM<CoinType> | CoinTypeUTXO<CoinType> }
>(arg: T): arg is Extract<T, { currency: CoinTypeUTXO<CoinType> }>

export function isUTXO<T extends ArgIsCurrency>(
  arg: T
): arg is Extract<
  T,
  { currency: CoinTypeUTXO<CoinType> } | CoinTypeUTXO<CoinType>
> {
  const objectCurrency = arg as { currency: CoinType }

  if (isObject(objectCurrency)) {
    return UTXO_COINS.includes(
      objectCurrency.currency as CoinTypeUTXO<CoinType>
    )
  }

  return UTXO_COINS.includes(arg as CoinTypeUTXO<CoinType>)
}

export function isSupportedBlockchain<T extends AllCoinType>(
  coin: T
): coin is SupportedCoin<T>

export function isSupportedBlockchain<T extends { currency: AllCoinType }>(
  arg: T
): arg is Extract<T, { currency: SupportedCoin<AllCoinType> }>

export function isSupportedBlockchain<T extends ArgIsAllCurrency>(
  arg: T
): arg is Extract<
  T,
  { currency: SupportedCoin<AllCoinType> } | SupportedCoin<AllCoinType>
> {
  const objectCurrency = arg as { currency: AllCoinType }

  if (isObject(objectCurrency)) {
    return COINS.includes(objectCurrency.currency as any)
  }

  return COINS.includes(arg as SupportedCoin<CoinType>)
}

export function isUnsupportedBlockchain<T extends AllCoinType>(
  coin: T
): coin is UnsupportedCoin<T>

export function isUnsupportedBlockchain<T extends { currency: AllCoinType }>(
  arg: T
): arg is Extract<T, { currency: UnsupportedCoin<AllCoinType> }>

export function isUnsupportedBlockchain<T extends ArgIsAllCurrency>(
  arg: T
): arg is Extract<
  T,
  { currency: UnsupportedCoin<AllCoinType> } | UnsupportedCoin<AllCoinType>
> {
  const objectCurrency = arg as { currency: AllCoinType }

  if (isObject(objectCurrency)) {
    return !COINS.includes(objectCurrency.currency as any)
  }

  return !COINS.includes(arg as any)
}

export function isEVM<T extends CoinType>(coin: T): coin is CoinTypeEVM<T>

export function isEVM<T extends { currency: CoinType }>(
  arg: T
): arg is Extract<T, { currency: CoinTypeEVM<CoinType> }>

export function isEVM<T extends ArgIsCurrency>(
  arg: T
): arg is Extract<
  T,
  { currency: CoinTypeEVM<CoinType> } | CoinTypeEVM<CoinType>
> {
  const objectCurrency = arg as { currency: CoinType }

  if (isObject(objectCurrency)) {
    return EVM_COINS.includes(objectCurrency.currency as CoinTypeEVM<CoinType>)
  }

  return EVM_COINS.includes(arg as CoinTypeEVM<CoinType>)
}

export function isBlockchainEVM<T extends BlockchainType>(
  coin: T
): coin is BlockchainTypeEVM<T>

export function isBlockchainEVM<T extends { blockchain: BlockchainType }>(
  arg: T
): arg is Extract<T, { blockchain: BlockchainTypeEVM<BlockchainType> }>

export function isBlockchainEVM<T extends ArgIsBlockchain>(
  arg: T
): arg is Extract<
  T,
  | { blockchain: BlockchainTypeEVM<BlockchainType> }
  | BlockchainTypeEVM<BlockchainType>
> {
  const objectCurrency = arg as { blockchain: BlockchainType }

  if (isObject(objectCurrency)) {
    return EVM_BLOCKCHAINS.includes(
      objectCurrency.blockchain as BlockchainTypeEVM<BlockchainType>
    )
  }

  return EVM_BLOCKCHAINS.includes(arg as BlockchainTypeEVM<BlockchainType>)
}

export function isBlockchainUTXO<T extends BlockchainType>(
  coin: T
): coin is BlockchainTypeUTXO<T>

export function isBlockchainUTXO<T extends { blockchain: BlockchainType }>(
  arg: T
): arg is Extract<T, { blockchain: BlockchainTypeUTXO<BlockchainType> }>

export function isBlockchainUTXO<T extends ArgIsBlockchain>(
  arg: T
): arg is Extract<
  T,
  | { blockchain: BlockchainTypeUTXO<BlockchainType> }
  | BlockchainTypeUTXO<BlockchainType>
> {
  const objectCurrency = arg as { blockchain: BlockchainType }

  if (isObject(objectCurrency)) {
    return UTXO_BLOCKCHAINS.includes(
      objectCurrency.blockchain as BlockchainTypeUTXO<BlockchainType>
    )
  }

  return UTXO_BLOCKCHAINS.includes(arg as BlockchainTypeUTXO<BlockchainType>)
}
